
import { defineComponent, inject, Ref, ref } from 'vue'
import DescriptionProduct from '@/components/product/DescriptionProduct.vue'
import Variations from '@/components/product/variations/index.vue'
import PhotosProduct from '@/components/product/PhotosProduct.vue'
import { IAddProduct } from '@/interfaces'
import { enumProductState, enumVariationState } from '@/constains'

export default defineComponent({
	name: 'Product',

	components: { Variations, DescriptionProduct, PhotosProduct },

	setup() {
		const product = inject<Ref<IAddProduct> | undefined>('product', undefined)

		const activeTab = ref(getTab())

		function getTab() {
			if (product?.value) {
				if (
					product.value.state === enumProductState.shooting &&
					product.value.variations.every(el => el.state === enumVariationState.moving)
				) {
					return '2'
				} else if (
					!product.value.variations.length ||
					product.value.variations.some(
						el =>
							[enumVariationState.counted, enumVariationState.marked, enumVariationState.partlymoving].includes(
								el.state
							)
						// el.state <= enumVariationState.marked || el.state === enumVariationState.partlymoving
					)
				) {
					return '1'
				} else return '0'
			}

			return '0'
		}

		return {
			activeTab,
			product
		}
	}
})
