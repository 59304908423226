
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import { Check, Close } from '@element-plus/icons-vue'
import axios from '@/http'
import VariationPrint from '@/components/product/variations/VariationPrint.vue'
import { IAddProduct, IProduct, ISizeValue, IVariation, ISizeReferences } from '@/interfaces'
import { enumProductState, enumVariationState } from '@/constains'

export default defineComponent({
	name: 'VariationsAdd',

	components: {
		VariationPrint,
		Check,
		Close
	},

	setup() {
		const isUserAllowedChange = inject<{ value: boolean }>('isUserAllowedChange')
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const product = inject<{ value: IAddProduct }>('product')
		const setProduct = inject<(product: IAddProduct | null) => void>('setProduct')

		const variations = ref<Array<Partial<IVariation>>>([])
		const sizeTypes = ref([])
		const sizeValues = ref<{ rows: Array<ISizeValue> } | null>(null)
		const sizeValuesByCategoryGroupId = ref<Array<Omit<ISizeValue, 'id'>>>([])
		const sizeReferences = ref<ISizeReferences[]>([])
		const canSelectSizeReferences = ref<{ [id: number]: boolean }>({})
		const defaultSizeReferences = ref<{ [id: number]: number }>({})

		const isCompleteVariations = computed(() => {
			return Number(product?.value?.state) >= enumProductState.collected
		})

		const isDisableSelect = computed(() => {
			return Number(product?.value.state) > enumProductState.collected
		})

		const isAllFieldUniq = computed(() => {
			const strSizeValueAndSizeType = variations.value.map(el => String(el.sizeValueId) + String(el.sizeTypeId))

			return strSizeValueAndSizeType.join() === [...new Set(strSizeValueAndSizeType)].join()
		})

		const isAllFieldValid = computed(() => {
			const notCompleteField = variations.value.filter((el: any) =>
				Object.keys(el).some((key: string) => {
					const item = el[key]
					if (key === 'sizeReferenceId') {
						const canEdit = canEditSizeReference(el)
						if (!canEdit) return false
					}
					return item === null || item === '' || item === undefined
				})
			).length

			return variations.value.length > 1 && notCompleteField === 1
		})

		const isUpdateCreatedVariations = computed(() => {
			if (!product?.value?.variations?.length || !isAllFieldUniq.value) return false

			return (
				isAllFieldValid.value &&
				variations.value
					.filter(el => el.id)
					.some((el, idx) => Object.values(el).join() !== Object.values(product?.value?.variations?.[idx] || []).join())
			)
		})

		const isSaveNewVariations = computed(() => {
			if (!isAllFieldUniq.value) return false

			const newVariations: Array<{ [key: string]: any }> = variations.value.filter(el => !el.id)

			const notCompleteField = newVariations.filter((el: any) =>
				Object.keys(el).some((key: string) => {
					const item = el[key]
					if (key === 'sizeReferenceId') {
						const canEdit = canEditSizeReference(el)
						if (!canEdit) return false
					}
					return item === null || item === '' || item === undefined
				})
			).length

			return newVariations.length > 1 && notCompleteField === 1
		})

		const isSave = computed(
			() =>
				(isUpdateCreatedVariations.value || isSaveNewVariations.value) &&
				Object.values(canSelectSizeReferences.value).every(bool => bool)
		)

		watch(
			() => product?.value.variations,
			() => {
				variations.value = JSON.parse(JSON.stringify(product?.value?.variations || []))

				addFieldVariation()
			},
			{ deep: true, immediate: true }
		)

		watch(variations, addFieldVariation, { deep: true })

		onMounted(async () => {
			const categoryGroupId = product?.value.category.categoryGroupId
			const gender = product?.value.gender
			if (categoryGroupId) {
				const [dataSizeValues, dataSizeTypes, dataSizeReferences, dataSizeValuesByCategoryGroupId] = await Promise.all([
					axios.post('/internal/size-value-list', { page: 0, limit: 700 }),
					axios.get('/internal/get-size-types-by-category-id/' + product?.value?.category.id),
					axios.get('/internal/size-reference-list-by-gender/' + gender),
					axios.post('/internal/get-size-values-by-category-group-id-and-by-gender/', {
						gender,
						categoryGroupId
					})
				])
				sizeValues.value = dataSizeValues.data
				sizeTypes.value = dataSizeTypes.data
				sizeReferences.value = dataSizeReferences.data
				sizeValuesByCategoryGroupId.value = filterSizeValuesByCategoryGroupId(dataSizeValuesByCategoryGroupId.data)
				for (const sizeReference of sizeReferences.value) {
					defaultSizeReferences.value[sizeReference.sizeValueId] = sizeReference.sizeReferenceId
				}
			} else {
				const [dataSizeValues, dataSizeTypes] = await Promise.all([
					axios.post('/internal/size-value-list', { page: 0, limit: 700 }),
					axios.get('/internal/get-size-types-by-category-id/' + product?.value?.category.id)
				])
				sizeValues.value = dataSizeValues.data
				sizeTypes.value = dataSizeTypes.data
				sizeValuesByCategoryGroupId.value = filterSizeValuesByCategoryGroupId([])
			}
		})

		const getSizeValues = (id: number | null) => (sizeValues.value?.rows || []).filter(el => el.sizeTypeId === id)

		function canEditSizeReference(variation: IVariation) {
			const categoryGroupId = product?.value.category.categoryGroupId
			return (
				!(Number.isInteger(variation.state) && variation.state !== enumVariationState.counted) &&
				!isDisableSelect.value &&
				variation.sizeValueId &&
				categoryGroupId &&
				sizeReferences.value?.length
			)
		}

		function filterSizeValuesByCategoryGroupId(sizeValues: ISizeValue[]) {
			const categoryGroupId = product?.value.category.categoryGroupId
			if (!categoryGroupId) {
				return [
					{
						id: null,
						name: 'Нет группы',
						sizeTypeId: -1,
						key: ''
					}
				]
			}
			if (!sizeValues?.length) {
				return [
					{
						id: null,
						name: 'Не требуется',
						sizeTypeId: -1,
						key: ''
					}
				]
			}
			return sizeValues
		}

		function onSelectSizeValue(variation: IVariation, order: number) {
			canSelectSizeReferences.value[order] = true
			const categoryGroupId = product?.value.category.categoryGroupId
			if (!categoryGroupId || !sizeReferences.value?.length) {
				variation.sizeReferenceId = null
				return
			}
			const sizeReference = sizeReferences.value.find(
				sizeReference => sizeReference.sizeValueId === variation.sizeValueId
			)
			variation.sizeReferenceId = sizeReference?.sizeReferenceId || null
			if (!variation.sizeReferenceId) {
				canSelectSizeReferences.value[order] = false
			}
		}

		function addFieldVariation() {
			if (
				variations.value.some((el: any) =>
					Object.keys(el).some((key: string) => {
						const item = el[key]
						if (key === 'sizeReferenceId') {
							const canEdit = canEditSizeReference(el)
							if (!canEdit) return false
						}
						return item === null || item === '' || item === undefined
					})
				)
			)
				return

			variations.value.push({
				sizeTypeId: null,
				sizeValueId: null,
				sizeReferenceId: null,
				originalQuantity: null
			})
		}

		function clearNoCompleteVariations() {
			variations.value = variations.value.filter(
				(el: any) =>
					!Object.keys(el).some((key: string) => {
						const item = el[key]
						if (key === 'sizeReferenceId') {
							const canEdit = canEditSizeReference(el)
							if (!canEdit) return false
						}
						return item === null || item === '' || item === undefined
					})
			)
		}

		function getPreparedVariations() {
			return variations.value.filter((el: any) =>
				Object.keys(el).every((key: string) => {
					const item = el[key]
					if (key === 'sizeReferenceId') {
						const canEdit = canEditSizeReference(el)
						if (!canEdit) return true
					}
					return item !== null && item !== '' && item !== undefined
				})
			)
		}

		function clearZero(val: string, variation: Partial<IVariation>) {
			if (Number(val[0]) === 0) {
				variation.originalQuantity = val.length === 1 ? null : Number(val.slice(1))
			}
		}

		const isSaving = ref(false)

		async function prepareVariations() {
			if (checkRole) {
				checkRole(Boolean(isUserAllowedChange?.value))
			}
			isSaving.value = true

			clearNoCompleteVariations()

			const preparedVariations = getPreparedVariations()

			const forUpdate = preparedVariations
				.filter(el => el.id)
				.filter(el => {
					const oldObj = product?.value?.variations?.find(item => item.id === el.id) || {}

					return Object.values(oldObj).join() !== Object.values(el).join()
				})

			const forSave = preparedVariations.filter(el => !el.id)

			if (forSave?.length) {
				const resVariations = await save(
					forSave.map(el => ({ ...el, originalQuantity: Number(el.originalQuantity), productId: product?.value?.id }))
				)

				const resChangeState = await changeStateToCollected(resVariations?.data)

				setProduct?.(resChangeState?.data || resVariations?.data)
			}

			if (forUpdate?.length) {
				const resVariations = await update(
					forUpdate.map(el => ({ ...el, id: Number(el.id), originalQuantity: Number(el.originalQuantity) }))
				)

				setProduct?.(resVariations?.data)
			}

			isSaving.value = false
		}

		function clearFieldsNotSavedVariation(
			variation: Pick<IVariation, 'originalQuantity' | 'sizeTypeId' | 'sizeValueId' | 'sizeReferenceId'>
		) {
			const foundIndex = variations.value.indexOf(variation)

			variations.value.splice(foundIndex, 1)
		}

		async function changeStateToCollected(product: IProduct) {
			if (product.state === enumProductState.collected) return null

			try {
				return await axios.get('/product/product-state-collected/' + product.id)
			} catch (e) {
				alert('Не удалось сменить стату продукта на collected')
			}
		}

		async function save(data: unknown) {
			try {
				return await axios.post('/product/add-variations', {
					data
				})
			} catch (e) {
				alert('Не удалось сохранить вариации')
			}
		}

		async function update(data: unknown) {
			try {
				return await axios.post('/product/update-variations', {
					data
				})
			} catch (e) {
				alert('Не удалось обновить вариации')
			}
		}

		return {
			variations,
			sizeValues,
			sizeTypes,
			product,
			isSave,
			isUpdateCreatedVariations,
			isSaveNewVariations,
			isCompleteVariations,
			isAllFieldUniq,
			isDisableSelect,
			canSelectSizeReferences,
			defaultSizeReferences,
			sizeValuesByCategoryGroupId,
			isSaving,

			enumVariationState,

			prepareVariations,
			onSelectSizeValue,
			getSizeValues,
			addFieldVariation,
			canEditSizeReference,
			clearZero,
			clearFieldsNotSavedVariation
		}
	}
})
