
import { Check } from '@element-plus/icons-vue'
import { computed, defineComponent, inject, onBeforeMount, ref, Ref, watch } from 'vue'
import { IAddProduct, IStorage } from '@/interfaces'
import MoveMaster from '@/components/storage/MoveMaster.vue'
import axios from '@/http'
import { enumProductState, enumVariationState } from '@/constains'

export interface IReqCountVarInStorage {
	variationsId?: number[]
	cellsId: number[]
}

export interface IResCountVarInStorage {
	raw: Array<IStorage>
	count: number
}

export default defineComponent({
	name: 'MoveMasterProductTransferWrap',

	components: { MoveMaster, Check },

	setup() {
		const product = inject<Ref<IAddProduct>>('product')
		const updateProduct = inject<() => void>('updateProduct')

		const isTransfer = computed(() => (product?.value.state || 0) >= enumProductState.shooting && !isAllTransfer.value)

		const isAllTransfer = computed(() => {
			return (
				product?.value?.variations?.length &&
				product.value.variations.every(
					el =>
						![enumVariationState.counted, enumVariationState.marked, enumVariationState.partlymoving].includes(el.state)
					// el.state > enumVariationState.marked && el.state !== enumVariationState.partlymoving
				)
			)
		})

		function next() {
			updateProduct?.()
		}

		// let countTryChangeState = 0

		// data: { setStorageData: IStorageSet }
		async function success() {
			// try {
			await getCountVarInStorageByShootingAndTransferApi()

			// await changeVariationState(data.setStorageData.variationId)
			// } catch (e) {
			// 	ElMessage.error('Ошибка в transfer' + e)
			//
			// 	countTryChangeState++
			// 	if (countTryChangeState === 1) success(data)
			// }
		}

		// async function changeVariationState(id: number) {
		// 	const countInStorage = varInStorage.value?.raw
		// 		.filter(el => Number(el.variationId) === id)
		// 		.reduce((acc, el) => acc + Number(el?.holded) + Number(el?.quantity), 0)
		//
		// 	const variation = product?.value.variations.find(el => Number(el.id) === id)
		//
		// 	if (Number(countInStorage) < Number(variation?.originalQuantity)) {
		// 		if (variation?.state !== enumVariationState.partlymoving) {
		// 			changeVariationStateApi(id, enumVariationState.partlymoving)
		// 		}
		// 	} else changeVariationStateApi(id, enumVariationState.moving)
		// }

		// async function changeVariationStateApi(id: number, state: enumVariationState) {
		// 	try {
		// 		await axios.post('/product/change-variation-state', {
		// 			id,
		// 			state
		// 		})
		// 	} catch (e) {}
		// }

		const getCountOriginalQuantity = computed<number>(() => {
			return (product?.value.variations || []).reduce((acc, el) => acc + Number(el.originalQuantity), 0)
		})

		const getAllVariationId = computed<Array<number>>(() => {
			return (product?.value.variations || []).map<number>(el => Number(el.id))
		})

		let varInStorage = ref<IResCountVarInStorage | null>(null)

		const getCountInStorage = computed<number>(() => {
			return varInStorage.value?.count || 0
		})

		watch(
			() => product?.value.state,
			() => {
				if (isTransfer.value) {
					getCountVarInStorageByShootingAndTransferApi()
				}
			}
		)

		onBeforeMount(async () => {
			await getCountVarInStorageByShootingAndTransferApi()
		})

		async function getCountVarInStorageByShootingAndTransferApi() {
			if (isTransfer.value && getAllVariationId.value.length)
				varInStorage.value = await checkCountVarInStorage([1, 2], getAllVariationId.value)
		}

		async function checkCountVarInStorage(
			cellsId: Array<number>,
			variationsId: Array<number>
		): Promise<IResCountVarInStorage | null> {
			const data: IReqCountVarInStorage = {
				variationsId,
				cellsId
			}

			try {
				return (await axios.post<IResCountVarInStorage>('/storage/get-count-variation-storage-by-cell-id', data)).data
			} catch (e) {
				return null
			}
		}

		return {
			isTransfer,
			varInStorage,
			getCountInStorage,
			getCountOriginalQuantity,
			product,
			enumVariationState,
			isAllTransfer,

			success,
			next
		}
	}
})
