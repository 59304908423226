
import { Check } from '@element-plus/icons-vue'
import { computed, defineComponent, inject, ref, Ref } from 'vue'
import { IAddProduct, IStorageSet } from '@/interfaces'
import MoveMaster from '@/components/storage/MoveMaster.vue'
import { AxiosResponse } from 'axios'
import { enumProductState, enumVariationState } from '@/constains'

// interface IChangeProductState {
// 	id: string
// 	state: enumProductState
// }

export default defineComponent({
	name: 'MoveMasterProductPhotozoneWrap',

	components: { MoveMaster, Check },

	setup() {
		const product = inject<Ref<IAddProduct>>('product')
		const setProduct = inject<(product: IAddProduct | null) => void>('setProduct')

		const isLoading = ref(false)

		const isToShooting = computed(() => {
			return (
				product?.value.variations.every(el => el.state === enumVariationState.marked) &&
				product.value.state === enumProductState.collected
			)
		})

		// let countTryChangeState = 0
		let productData: null | AxiosResponse<IAddProduct> = null
		async function success(data: { successData: AxiosResponse<IAddProduct>; setStorageData: IStorageSet }) {
			productData = data.successData

			// isLoading.value = true
			//
			// const changeData: IChangeProductState = {
			// 	id: product?.value.id || '',
			// 	state: enumProductState.shooting
			// }
			//
			// const isOneOriginalQuantity =
			// 	product?.value?.variations.find(el => String(el.id) === String(data.setStorageData.variationId))
			// 		?.originalQuantity === 1
			//
			// try {
			// 	await axios.post('/product/change-variation-state', {
			// 		id: data.setStorageData.variationId,
			// 		state: isOneOriginalQuantity ? enumVariationState.moving : enumVariationState.partlymoving
			// 	})
			//
			// 	productData = await axios.post('/product/change-state', changeData)
			next()
			// } catch (e) {
			// 	ElMessage.error('Не удалось изменить state товара на shooting ' + e)
			//
			// 	countTryChangeState++
			// 	if (countTryChangeState === 1) success(data)
			// }
			//
			// isLoading.value = false
		}

		function next() {
			setProduct?.(productData?.data || null)
		}

		return {
			isToShooting,
			product,
			isLoading,

			enumProductState,

			success,
			next
		}
	}
})
