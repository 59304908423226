import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DescriptionProduct = _resolveComponent("DescriptionProduct")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_Variations = _resolveComponent("Variations")!
  const _component_PhotosProduct = _resolveComponent("PhotosProduct")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_tabs, {
      lazy: "",
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
      type: "card",
      class: "demo-tabs"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, { label: "Описание" }, {
          default: _withCtx(() => [
            _createVNode(_component_DescriptionProduct)
          ]),
          _: 1
        }),
        (_ctx.product)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              lazy: "",
              label: "Вариации"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Variations)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.product)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 1,
              lazy: "",
              label: "Фото"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PhotosProduct)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}