
import { defineComponent, inject, Ref, ref, watchEffect } from 'vue'
import { IAddProduct } from '@/interfaces'
import { ElMessage } from 'element-plus'
import axios from '@/http'
export default defineComponent({
	setup() {
		const isUserAllowedChangePhoto = inject<Ref<boolean> | undefined>('isUserAllowedChangePhoto', undefined)
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const product = inject<Ref<IAddProduct> | undefined>('product', undefined)
		const updateProduct = inject<(() => void) | undefined>('updateProduct', undefined)
		const isProductLoaded = ref(false)
		const videoUrl = ref()

		watchEffect(() => {
			videoUrl.value = product?.value.video
		})

		async function setProductVideo() {
			if (product) {
				if (checkRole) {
					checkRole(Boolean(isUserAllowedChangePhoto?.value))
				}
				try {
					if (videoUrl.value === product?.value.video) return ElMessage.warning('Вы не внесли изменений')
					await axios.post('/product/add-video-to-product', {
						id: product?.value.id,
						video: videoUrl.value
					})
					await updateProduct?.()
					ElMessage.success('Видео успешно добавлено')
				} catch (e) {
					ElMessage.error('Не удалось добавить видео')
				}
			}
		}
		return {
			isProductLoaded,
			product,
			setProductVideo,
			videoUrl
		}
	}
})
