
import { computed, defineComponent, onBeforeMount, ref, reactive, toRaw, inject, watch, Ref } from 'vue'
import axios from '@/http'
import { useRouter } from 'vue-router'
import { IAddProduct, IAddProductUpdate, ICountry } from '@/interfaces'
import { enumLotStates, enumProductState, localizeProductStates } from '@/constains'

interface ICommonData {
	name: string
	id?: number
}

interface ICommonDataWithState extends ICommonData {
	state: number
}

interface ICommonDataWithCategory extends ICommonData {
	parentId: number
	gender: number
}

interface ICommonDataWithTypeCategory extends ICommonData {
	categoryId: number
}

interface IDataListCommon<T> {
	count: number
	rows: Array<T>
}
interface IForm {
	brand: string | number
	name?: string
	country: string | number
	retailPrice?: string | number
	totalWeight: string | number
	totalPieces: string | number
	itemEuroPrice: string | number
	isByWeight: string | boolean
	material: number[]
	color: number[]
	seasons: number[]
	categoriesFirst?: string | number
	categoriesLast: string | number
	categoryType: string | number
	lot: string | number
	price: string | number
	description?: string
	internalParam1?: string
	internalParam2?: string
	gender: string | number
	[key: string]: any
}

type DecimalInputType = 'totalWeight' | 'totalPieces' | 'itemEuroPrice' | 'price' | 'retailPrice'

export default defineComponent({
	name: 'DescriptionProduct',

	setup() {
		const isUserAllowedChange = inject<{ value: boolean }>('isUserAllowedChange')
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const product = inject<Ref<IAddProduct> | undefined>('product', undefined)
		const setProduct = inject<((product: IAddProduct | null) => void) | undefined>('setProduct', undefined)

		const lots = ref<IDataListCommon<ICommonDataWithState>>()
		const brands = ref<IDataListCommon<ICommonData>>()
		const categories = ref<IDataListCommon<ICommonDataWithCategory>>()
		const categoriesTypes = ref<IDataListCommon<ICommonDataWithTypeCategory>>()
		const colors = ref<IDataListCommon<ICommonData>>()
		const materials = ref<IDataListCommon<ICommonData>>()
		const seasons = ref<IDataListCommon<ICommonData>>()
		const countries = ref<Array<ICountry>>([])

		const form = reactive<IForm>({
			brand: '',
			name: '',
			country: '',
			retailPrice: '',
			totalWeight: '',
			totalPieces: '',
			isByWeight: '',
			itemEuroPrice: '',
			material: [],
			color: [],
			seasons: [],
			categoriesFirst: '',
			categoriesLast: '',
			categoryType: '',
			lot: '',
			price: '',
			gender: '',
			description: '',
			internalParam1: '',
			internalParam2: ''
		})

		const disabledModels = reactive({
			gender: false,
			categoriesFirst: false,
			categoriesLast: false
		})

		const isLoading = ref(true)

		const isFetching = ref(false)

		const isByWeightSelectOptions = toRaw([
			{
				value: true,
				label: 'Килограммы'
			},
			{
				value: false,
				label: 'Штуки'
			}
		])

		const genders = toRaw([
			{
				name: 'women',
				key: 0,
				label: 'Женщины'
			},
			{
				name: 'men',
				key: 1,
				label: 'Мужчины'
			},
			{
				name: 'girls',
				key: 2,
				label: 'Девочки'
			},
			{
				name: 'boys',
				key: 3,
				label: 'Мальчики'
			},
			{
				name: 'bulk',
				key: 4,
				label: 'Лоты'
			}
		])

		function getStrPreatyTime(time: string) {
			return new Date(time).toLocaleString()
		}

		const categoriesFirstLevel = computed(() => {
			const result = (categories.value?.rows || []).filter(el => !el.parentId && el.gender === form.gender)
			if (result?.length) {
				result.unshift({
					name: 'Не выбрано',
					parentId: 0,
					gender: 0
				})
			}
			return result
		})

		const categoriesLastLevel = computed(() => {
			const result = (categories.value?.rows || []).filter(el => el.parentId === form.categoriesFirst)
			if (result?.length) {
				result.unshift({
					name: 'Не выбрано',
					parentId: 0,
					gender: 0
				})
			}
			return result
		})

		const categoriesTypesByCategoryId = computed(() => {
			const result = (categoriesTypes.value?.rows || []).filter(el => el.categoryId === form.categoriesLast)
			if (result?.length) {
				result.unshift({
					name: 'Не выбрано',
					categoryId: 0
				})
			}
			return result
		})

		const getLotsOnlyStateCounted = computed(() => {
			return lots.value?.rows.filter(el => el.state === enumLotStates.counted)
		})

		const isSaveProduct = computed(() => {
			const gender = form.gender
			if (!gender && gender !== 0) return false
			let arrCheck = ['seasons', 'categoryType', 'lot', 'price', 'gender', 'country']
			if (gender === 4) {
				arrCheck = [...arrCheck, 'name', 'totalWeight', 'totalPieces', 'isByWeight', 'itemEuroPrice']
			} else {
				arrCheck = [...arrCheck, 'brand', 'color', 'material']
			}

			return arrCheck.every(
				(el: string) =>
					(el in form && (form[el] || form[el] === 0 || form[el] === false) && !Array.isArray(form[el])) ||
					form[el]?.length
			)
		})

		watch(
			() => [product, categories],
			() => {
				if (product) {
					const oldProduct = {
						brandId: product.value?.brand?.id,
						categoryId: product.value.category.id,
						colors: product.value.colors.map(el => el.id),
						materials: product.value.materials.map(el => el.id),
						seasons: product.value.seasons.map(el => el.id),
						gender: product.value.gender,
						lotId: product.value.lot.id,
						price: product.value.price,
						countryId: product.value.countryId,
						totalWeight: product.value.totalWeight,
						totalPieces: product.value.totalPieces,
						isByWeight: product.value.isByWeight,
						itemEuroPrice: product.value.itemEuroPrice,
						retailPrice: product.value.retailPrice,
						description: product.value.description,
						internalParam1: product.value.internalParam1,
						internalParam2: product.value.internalParam2,
						name: product.value.name,
						typeId: product.value.type.id,
						id: product.value.id
					}

					setValuesOldProduct(oldProduct)
				}
			},
			{ deep: true }
		)

		const router = useRouter()

		function getFormattedData() {
			const gender = form.gender
			let dataSave: any = {
				lotId: Number(form.lot),
				categoryId: form.categoriesLast,
				typeId: form.categoryType,
				price: form.price,
				gender: form.gender,
				countryId: form.country,
				seasons: form.seasons,
				isBundle: gender === 4,
				name: form.name || null,
				description: form.description || null,
				internalParam1: form.internalParam1 || null,
				internalParam2: form.internalParam2 || null,
				retailPrice: form.retailPrice || null
			}
			if (gender === 4) {
				dataSave = {
					...dataSave,
					totalWeight: +form.totalWeight,
					totalPieces: +form.totalPieces,
					isByWeight: form.isByWeight,
					itemEuroPrice: form.itemEuroPrice
				}
			} else {
				dataSave = {
					...dataSave,
					brandId: form.brand,
					colors: form.color,
					materials: form.material
				}
			}
			return dataSave
		}
		async function save() {
			if (checkRole) {
				checkRole(Boolean(isUserAllowedChange?.value))
			}

			isFetching.value = true
			const gender = form.gender
			if (!gender && gender !== 0) return false
			let dataSave: any = getFormattedData()

			try {
				const product = await axios.post('/product/create', dataSave)

				router.push('/product/' + product.data.id)
			} catch (e) {
				alert('Не удалось сохранить продукт')
			}

			isFetching.value = false
		}

		async function update() {
			if (checkRole) {
				checkRole(Boolean(isUserAllowedChange?.value))
			}
			isFetching.value = true

			const gender = form.gender
			if (!gender && gender !== 0) return false

			let dataSave: any = getFormattedData()

			try {
				const resProduct = await axios.post('/product/update', { id: product?.value.id, data: dataSave })

				setProduct?.(resProduct.data)
			} catch (e) {
				alert('Не удалось обновить продукт')
			}

			isFetching.value = false
		}

		function validateDecimal(modelName: DecimalInputType) {
			return (val: string) => {
				if (!(modelName in form)) return
				if (!Number(val)) form[modelName] = val.slice(0, -1)
			}
		}

		function setValuesOldProduct(product: IAddProductUpdate) {
			const categoryParentId = (categories.value?.rows || []).find(el => el.id === product.categoryId)?.parentId
			const firstCategoryId = (categories.value?.rows || []).find(el => el.id === categoryParentId)?.id

			form.lot = product.lotId
			form.categoriesFirst = firstCategoryId
			form.categoriesLast = product.categoryId
			form.categoryType = product.typeId
			form.brand = product.brandId
			form.country = product.countryId
			form.price = product.price
			form.retailPrice = product.retailPrice
			form.description = product.description
			form.internalParam1 = product.internalParam1
			form.internalParam2 = product.internalParam2
			form.totalWeight = product.totalWeight
			form.totalPieces = product.totalPieces
			form.isByWeight = product.isByWeight
			form.itemEuroPrice = product.itemEuroPrice
			form.name = product.name
			form.gender = product.gender
			form.material = product.materials as []
			form.color = product.colors as []
			form.seasons = product.seasons as []
		}

		function sortOrderAlph(x: { name: string }, y: { name: string }) {
			return x.name.localeCompare(y.name)
		}

		onBeforeMount(async () => {
			const [lotsRes, brandsRes, categoriesRes, categoriesTypesRes, colorsRes, materialsRes, seasonsRes, countriesRes] =
				await Promise.all([
					axios.post('/lot/list', { page: 0, limit: 500 }),
					axios.post('/internal/brand-list', { page: 0, limit: 500 }, { cache: true }),
					axios.post('/internal/category-list', { page: 0, limit: 500 }, { cache: true }),
					axios.post('/internal/category-type-list', { page: 0, limit: 500 }, { cache: true }),
					axios.post('/internal/color-list', { page: 0, limit: 500 }, { cache: true }),
					axios.post('/internal/material-list', { page: 0, limit: 500 }, { cache: true }),
					axios.post('/internal/season-list', { page: 0, limit: 500 }, { cache: true }),
					axios.get('/internal/get-all-country', { cache: true })
				])

			// const lotsRes = (await axios.post('/lot/list', { page: 0, limit: 500 })).data
			lotsRes.data.rows.sort(sortOrderAlph)
			lots.value = lotsRes.data

			// const brandsRes = (await axios.post('/internal/brand-list', { page: 0, limit: 500 }, { cache: true })).data
			brandsRes.data.rows.sort(sortOrderAlph)
			brands.value = brandsRes.data

			// const categoriesRes = (await axios.post('/internal/category-list', { page: 0, limit: 500 }, { cache: true })).data
			categoriesRes.data.rows.sort(sortOrderAlph)
			categories.value = categoriesRes.data

			// const categoriesTypesRes = (
			// 	await axios.post('/internal/category-type-list', { page: 0, limit: 500 }, { cache: true })
			// ).data
			categoriesTypesRes.data.rows.sort(sortOrderAlph)
			categoriesTypes.value = categoriesTypesRes.data

			// const colorsRes = (await axios.post('/internal/color-list', { page: 0, limit: 500 }, { cache: true })).data
			colorsRes.data.rows.sort(sortOrderAlph)
			colors.value = colorsRes.data

			// const materialsRes = (await axios.post('/internal/material-list', { page: 0, limit: 500 }, { cache: true })).data
			materialsRes.data.rows.sort(sortOrderAlph)
			materials.value = materialsRes.data

			// const seasonsRes = (await axios.post('/internal/season-list', { page: 0, limit: 500 }, { cache: true })).data
			seasonsRes.data.rows.sort(sortOrderAlph)
			seasons.value = seasonsRes.data

			countriesRes.data.sort(sortOrderAlph)
			countries.value = countriesRes.data

			isLoading.value = false
		})

		return {
			product,

			brands,
			categories,
			categoriesTypes,
			colors,
			materials,
			seasons,
			countries,
			getLotsOnlyStateCounted,
			genders,

			categoriesFirstLevel,
			categoriesLastLevel,
			categoriesTypesByCategoryId,

			form,
			isFetching,

			disabledModels,
			isByWeightSelectOptions,

			isSaveProduct,
			isLoading,

			enumProductState,
			localizeProductStates,

			getStrPreatyTime,
			validateDecimal,
			save,
			update
		}
	}
})
