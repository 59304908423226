
import { defineComponent, inject } from 'vue'
import VariationsAdd from '@/components/product/variations/VariationsAdd.vue'
import MoveMasterProductPhotozoneWrap from '@/components/storage/MoveMasterProductPhotozoneWrap.vue'
import MoveMasterProductTransferWrap from '@/components/storage/MoveMasterProductTransferWrap.vue'

export default defineComponent({
	name: 'Variations',

	components: { MoveMasterProductTransferWrap, MoveMasterProductPhotozoneWrap, VariationsAdd },
	setup() {
		const isUserAllowedChange = inject<{ value: boolean }>('isUserAllowedChange')
		return {
			isUserAllowedChange
		}
	}
})
