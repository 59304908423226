
import { defineComponent, inject, onMounted, Ref, ref, watchEffect } from 'vue'
import { IAddProduct } from '@/interfaces'
import { ElMessage } from 'element-plus'
import axios from '@/http'
export default defineComponent({
	setup() {
		const isUserAllowedChangePhoto = inject<Ref<boolean> | undefined>('isUserAllowedChangePhoto', undefined)
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const product = inject<Ref<IAddProduct> | undefined>('product', undefined)
		const updateProduct = inject<(() => void) | undefined>('updateProduct', undefined)
		const activePhotomodels = ref()
		const isProductLoaded = ref(false)
		const selectedPhotomodels = ref()
		onMounted(async () => {
			const result = await axios.get('/internal/get-photomodels-active')
			activePhotomodels.value = result.data
		})

		watchEffect(() => {
			selectedPhotomodels.value = product?.value.photomodelId
			if (product) isProductLoaded.value = true
		})

		async function setProductPhotomodel() {
			if (product) {
				if (checkRole) {
					checkRole(Boolean(isUserAllowedChangePhoto?.value))
				}
				try {
					if (!selectedPhotomodels.value) return ElMessage.error('Фотомодель не выбрана')
					if (selectedPhotomodels.value === product?.value.photomodelId)
						return ElMessage.warning('Вы не внесли изменений')
					await axios.post('/product/add-photomodel-to-product', {
						id: product?.value.id,
						photomodelId: selectedPhotomodels.value
					})
					await updateProduct?.()
					ElMessage.success('Фотомодель успешно добавлено')
				} catch (e) {
					ElMessage.error('Не удалось добавить фотомодель')
				}
			}
		}
		return {
			isProductLoaded,
			product,
			setProductPhotomodel,
			activePhotomodels,
			isUserAllowedChangePhoto,
			selectedPhotomodels
		}
	}
})
