
import { computed, defineComponent, inject, ref, Ref } from 'vue'
import { IAddProduct, IStorageSet } from '@/interfaces'
import MoveMaster from '@/components/storage/MoveMaster.vue'
import { AxiosResponse } from 'axios'
import { enumProductState } from '@/constains'

// interface IChangeProductState {
// 	id: string
// 	state: enumProductState
// }

export default defineComponent({
	name: 'MoveMasterProductPhotozoneTransferWrap',

	components: { MoveMaster },

	setup() {
		const product = inject<Ref<IAddProduct>>('product')
		const setProduct = inject<(product: IAddProduct | null) => void>('setProduct')

		const isLoading = ref(false)

		const isToTransfer = computed(() => {
			return product?.value.state === enumProductState.shooting && product.value.photos?.length
		})

		// let countTryChangeState = 0
		let productData: null | AxiosResponse<IAddProduct> = null

		async function success(data: { successData: AxiosResponse<IAddProduct>; setStorageData: IStorageSet }) {
			if (data.successData?.data) {
				productData = data.successData
			}

			// try {
			// const changeData: IChangeProductState = {
			// 	id: product?.value.id || '',
			// 	state: enumProductState.shooted
			// }

			// productData = await axios.post('/product/change-state', changeData)
			// } catch (e) {
			// 	ElMessage.error('Не удалось изменить state товара на shooted ' + e)
			//
			// 	countTryChangeState++
			// 	if (countTryChangeState === 1) success(data)
			// }
		}

		function next() {
			setProduct?.(productData?.data || null)
		}

		return {
			isToTransfer,
			product,
			isLoading,

			enumProductState,

			success,
			next
		}
	}
})
