
import { computed, defineComponent, inject, onMounted, provide, Ref, ref } from 'vue'
import { Close, SuccessFilled, Plus } from '@element-plus/icons-vue'
import draggable from 'vuedraggable'
import { ElMessage } from 'element-plus'
import axios from '@/http'
import { getUrlFile } from '@/utils/getUrlImg'
import { IFile, IAddProduct } from '@/interfaces'
import { useStore } from 'vuex'
import MoveMasterProductPhotozoneTransferWrap from '@/components/storage/MoveMasterProductPhotozoneTransferWrap.vue'
import { enumProductState } from '@/constains'
import SetPhotomodel from './SetPhotomodel.vue'
import SetVideoUrl from './SetVideoUrl.vue'

interface IUploadFile {
	name: string
	uid: number
	url: string
	raw: File
	created?: Date
	id: string
}

export default defineComponent({
	name: 'PhotosProduct',

	components: {
		MoveMasterProductPhotozoneTransferWrap,
		draggable,
		Close,
		SuccessFilled,
		Plus,
		SetPhotomodel,
		SetVideoUrl
	},

	setup() {
		const store = useStore()
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const product = inject<Ref<IAddProduct> | undefined>('product', undefined)
		const updateProduct = inject<(() => void) | undefined>('updateProduct', undefined)

		const isLoading = ref<boolean>(false)

		const disableAddPhoto = computed(
			() => product?.value.state !== enumProductState.shooting || product.value.photos?.length > 0
		)

		const isUserAllowedChangePhoto = computed(() => {
			const userRoleIds = store.state.auth?.userRoleIds
			const allowedRoles = [4, 5, 9]
			let isUserCanChange = false
			for (const userRoleId of userRoleIds) {
				if (allowedRoles.includes(userRoleId)) {
					isUserCanChange = true
				}
			}
			return isUserCanChange
		})

		const isUserAllowedChangeTransferPhoto = computed(() => {
			const userRoleIds = store.state.auth?.userRoleIds
			const allowedRoles = [4, 5, 6, 9]
			let isUserCanChange = false
			for (const userRoleId of userRoleIds) {
				if (allowedRoles.includes(userRoleId)) {
					isUserCanChange = true
				}
			}
			return isUserCanChange
		})

		provide('isUserAllowedChangePhoto', isUserAllowedChangePhoto)

		const uploader = ref()
		const photosList = ref([])
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const photos = ref<Array<any>>([])

		onMounted(() => {
			updatePhotos()
		})

		function updatePhotos() {
			const project = process.env.VUE_APP_ENV === 'dev' ? 'dev' : 'production'
			if (product?.value.photos?.length) {
				photos.value = ((product.value.photos as Array<IFile>) || []).map(el => ({
					...el,
					url: getUrlFile({
						entityType: el.entityType,
						state: el.state,
						project,
						extension: el.extension,
						size: 's',
						id: el.id,
						server: el.server
					})
				}))
			}
		}

		function onChange(file: IUploadFile) {
			photos.value = [
				...photos.value,
				{
					...file,
					url: URL.createObjectURL(file.raw)
				}
			]
		}

		async function removePhoto(file: IUploadFile) {
			try {
				if (file?.created && file?.id) {
					const userAnswer = confirm('Данное изображение уже загружено. Вы точно хотите его удалить?')
					if (!userAnswer) return
					const idx = photos.value.findIndex(photo => photo.id === file.id)
					await axios.post(process.env.VUE_APP_URL_MAIN_BACK_API + '/media/delete', {
						ids: [file.id]
					})
					await Promise.all(
						photos.value.slice(idx + 1).map(async photo => {
							if (!photo.created) return
							await axios.post(process.env.VUE_APP_URL_MAIN_BACK_API + '/media/update-order', [
								{
									mediaId: photo.id,
									order: photo.order - 1
								}
							])
						})
					)
					photos.value = photos.value.filter(photo => photo.id !== file.id)
				} else {
					uploader.value.handleRemove(uploader.value.uploadFiles.find((el: IUploadFile) => el.uid === file.uid))
					photos.value = photos.value.filter(photo => photo.uid !== file.uid)
				}
			} catch (e) {
				console.error('PhotosProduct: ', e)
			}
		}

		async function save() {
			if (checkRole) {
				checkRole(Boolean(isUserAllowedChangePhoto?.value))
			}
			isLoading.value = true

			try {
				await Promise.allSettled(
					photos.value.map(async (photo, idx) => {
						const formData = new FormData()
						const orderNumber = idx + 1
						if (photo.created) {
							// если фото уже есть на бэке и ее порядок не изменился
							// то зачем её снова загружать?
							if (photo.order === orderNumber) return
							await axios.post(process.env.VUE_APP_URL_MAIN_BACK_API + '/media/update-order', [
								{
									mediaId: photo.id,
									order: orderNumber
								}
							])
							return
						}
						formData.append('id', String(product?.value.id))
						formData.append('entityType', 'product')
						formData.append('order', orderNumber.toString())
						formData.append('files', photo.raw)
						await axios.post(process.env.VUE_APP_URL_MAIN_BACK_API + '/media', formData)
					})
				)

				uploader.value.clearFiles()

				await updateProduct?.()
				updatePhotos()
			} catch (e) {
				ElMessage.error('Не удалось загрузить фото')
			}

			isLoading.value = false
		}

		return {
			photos,
			photosList,
			uploader,
			product,
			isLoading,
			isUserAllowedChangeTransferPhoto,
			disableAddPhoto,
			onChange,
			removePhoto,
			save
		}
	}
})
