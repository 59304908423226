
import { Check } from '@element-plus/icons-vue'
import { computed, defineComponent, inject, ref, watchEffect } from 'vue'
import axios from '@/http'
import { IAddProduct, IVariation } from '@/interfaces'
import JsBarcode from 'jsbarcode'
import { enumProductState, enumVariationState } from '@/constains'

interface ISizeValue {
	id: number
}

export default defineComponent({
	name: 'VariationPrint',

	components: {
		Check
	},

	props: {
		sizeValues: {
			type: Array
		},
		sizeTypes: {
			type: Array
		}
	},

	setup(props) {
		const isUserAllowedChange = inject<{ value: boolean }>('isUserAllowedChange')
		const checkRole = inject<((bool: boolean) => never) | undefined>('checkRole', undefined)
		const product = inject<{ value: IAddProduct }>('product')

		const sizeValues: Array<ISizeValue> | null = (props?.sizeValues as Array<ISizeValue>) || null
		const sizeTypes: Array<ISizeValue> | null = (props?.sizeTypes as Array<ISizeValue>) || null
		const variations = ref<Array<IVariation>>([])

		// const isAllMarked = computed(() => product?.value.variations.every(el => el.state >= enumVariationState.marked))

		const isAllMarked = computed(() => product?.value.variations.every(el => el.state != enumVariationState.counted))

		function getProductTag(variation: any) {
			if (!product?.value) return []
			const good = product.value
			const goodName = good.name
			if (goodName) {
				const partSize = 20
				const result = []
				for (let goodNamePartLength = 0; goodName.length > goodNamePartLength; goodNamePartLength += partSize) {
					const part = goodName.slice(goodNamePartLength, goodNamePartLength + partSize)
					result.push(part)
				}
				return result
			}
			const type = good?.type?.name
			const brand = good?.brand?.name
			const sizeType = variation?.sizeType?.name
			const sizeValue = variation?.sizeValue?.name
			const colors = good.colors.map(item => item.name).join(',')
			let size: string | undefined = `${sizeType} ${sizeValue}`
			if (sizeType === sizeValue) {
				size = sizeType
			}

			return [`${type || ''} ${brand || ''}`, `${size}`, `${colors}`]
		}

		watchEffect(() => {
			variations.value = (product?.value.variations || []).map(el => {
				return {
					...el,
					sizeValue: fromArrayObjectById(el.sizeValueId, sizeValues),
					sizeType: fromArrayObjectById(el.sizeTypeId, sizeTypes),
					checked: Number.isInteger(el.state) && el.state !== enumVariationState.counted
					// checked: el.state === enumVariationState.marked || el.state > enumVariationState.marked
				}
			})
		})

		function fromArrayObjectById(id: number | null | undefined, where: Array<ISizeValue> | null) {
			if (!id || !Array.isArray(where)) return null
			return where.find(el => el?.id === id)
		}

		// async function checkboxEvent(bool: boolean, variation: IVariation) {
		// 	if (enumVariationState.marked === variation.state) return
		//
		// 	try {
		// 		await axios.get('/product/variation-state-marked/' + variation.id)
		//
		// 		variation.state = enumVariationState.marked
		// 		changeVariation(Number(variation.id), enumVariationState.marked)
		// 	} catch (e) {
		// 		alert('Не удалось сменить статус вариации на marked')
		// 	}
		// }

		async function checkboxEvent(bool: boolean, variation: IVariation) {
			if (checkRole) {
				checkRole(Boolean(isUserAllowedChange?.value))
			}
			const state = bool ? enumVariationState.marked : enumVariationState.counted
			try {
				await axios.post('/product/change-variation-state', {
					id: Number(variation.id),
					state
				})

				changeVariation(Number(variation.id), state)
			} catch (e) {
				alert('Не удалось сменить статус вариации на marked')
			}
		}

		const cellNameArr = ref<unknown>([])

		async function checkCellName(id: number) {
			const res = await axios.get('/internal/get-cell-by-variation-id/' + id)
			if (Array.isArray(res.data) && res.data.length) {
				const array = res.data.map(data => {
					return `${data.cell.name} (Q-${data.quantity}, H-${data.holded})`
				})
				cellNameArr.value = {
					[id]: array
				}
			} else {
				alert('Товар не найден на складе')
			}
		}

		function changeVariation(id: number, state: enumVariationState) {
			const variation = product?.value.variations.find(el => Number(el.id) === Number(id)) as IVariation

			variation.state = state
		}

		function printBarcode(variation: IVariation) {
			if (checkRole) {
				checkRole(Boolean(isUserAllowedChange?.value))
			}
			JsBarcode('#barcode_' + variation.id, String(variation.id), {
				displayValue: false,
				height: 120,
				margin: 0
			})

			const innerContents = document.getElementById('print_' + variation.id)?.innerHTML
			var popupWindow = window.open('', 'Печать штрихкода')

			popupWindow?.document.write(
				'<!DOCTYPE html><html><head><style>@media print { body { -webkit-print-color-adjust: exact; } }</style></head><body><div style="display: inline-block;text-align: center;font-family: monospace;font-size: 20px;line-height: 1;overflow: hidden; width: 100%"> ' +
					innerContents +
					'</div></body></html>'
			)
			setTimeout(function () {
				popupWindow?.focus()
				popupWindow?.print()
				popupWindow?.close()
			}, 0)
		}

		return {
			variations,
			enumVariationState,
			product,
			enumProductState,
			isAllMarked,
			isUserAllowedChange,
			printBarcode,
			checkCellName,
			cellNameArr,
			getProductTag,
			checkboxEvent
		}
	}
})
